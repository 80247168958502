<template>
  <div>
    <el-card shadow="never">
      <template #header>
        <div>
          <span>今日数据</span>
          <span style="color: #a0a0a0; font-size: 12px; margin-left: 16px">
            更新时间：{{ workbench.today.time }}
          </span>
        </div>
      </template>
      <div class="flex flex-wrap" style="padding: 20px">
        <div class="item">
          <div class="item-title">线索数量</div>
          <div class="item-number">{{ workbench.today.today_clue }}</div>
          <div style="color: #a0a0a0; font-size: 14px; margin-top: 10px">
            总：{{ workbench.today.total_clue }}
          </div>
        </div>
        <div class="item">
          <div class="item-title">站点数量</div>
          <div class="item-number">{{ workbench.today.today_site }}</div>
          <div style="color: #a0a0a0; font-size: 14px; margin-top: 10px">
            总：{{ workbench.today.total_site }}
          </div>
        </div>

      </div>
    </el-card>
  </div>
</template>

<script>
import { apiWorkbench } from "@/api/workbench";

export default {
  data() {
    return {
      workbench: {
        today: {},
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      apiWorkbench().then((resp) => {
        this.workbench.today = resp.today;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.item {
  width: 25%;
}

.item-title {
  line-height: 40px;
}

.item-number {
  font-size: 30px;
}
</style>
